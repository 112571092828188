import { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
import Paper from "@material-ui/core/Paper";
import { Divider, Grid, Typography } from "@material-ui/core";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import AlertConfirm from "../alertConfirm.component";
import Tooltip from "@mui/material/Tooltip";
import SvgIcon from "@mui/material/SvgIcon";
import CopyIcon from "@mui/icons-material/ContentCopyOutlined";
import ExpandIcon from "@mui/icons-material/ExpandMore";
import collapseIcon from "@mui/icons-material/ExpandLess";


const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "6px 16px",
    backgroundColor: "#ffe6e6 !important",
  },
  opposite: {
    flex: "none",
  },
  oppositeTime: {
    fontSize: "10px",
  },
  errorTail: {
    backgroundColor: theme.palette.error.main,
  },
  successTail: {
    backgroundColor: theme.palette.success.main,
  },
  collapsable: {
    textAlign: "right",
    color: "#575756",
    marginRight: "20px",
    "&:hover":{
      color:"#da163d"
    }
  },
  icon: {
    cursor:"pointer",
  },
  link: {
    color: "#da163d",
    "&:hover":{
      color:"#b70120"
    }
  },
  text: {
    overflowWrap: "anywhere"
  },
}));
export const TransactionTimeline = (props) => {
  const { timeLineData, isFull, enabledCollapsedAll } = props;
  const [isOpen, setIsOpen] = useState([]);
  const [isCollapsedAll, setIsCollapsedAll] = useState(false);
  const [confirmInactivate, setConfirmInactivate] = useState({
    open: false,
    message: ""
  });
  const classes = useStyles();

  const toggle = (index) => () => {
    isOpen[index] = !isOpen[index];
    setIsOpen([...isOpen]);
  };

  const parseDateTime = (createdAt) => {
    if (!createdAt) {
      return { date: "N/A", time: "N/A" };
    }

    const [date, time] = createdAt.split(" ").length > 1 ? createdAt.split(" "):createdAt.split("T");
    return { date, time };
  };

  const convertTime = (time) => {
    if (!time || typeof time !== "string") {
      console.warn("Invalid time input:", time);
      return "N/A";
    }

    const parts = time.split(":");
    if (parts.length !== 3) {
      console.error("Invalid time format:", time);
      return "Invalid Time";
    }

    const hours24 = Number(parts[0]);
    const minutes = Number(parts[1]);
    const seconds = Number(parts[2]);

    if (isNaN(hours24) || isNaN(minutes) || isNaN(seconds)) {
      console.error("Invalid time values:", parts);
      return "Invalid Time";
    }

    const period = hours24 >= 12 ? "PM" : "AM";
    const hours12 = hours24 % 12 || 12;
    const formattedTime = `${hours12.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds} ${period}`;

    return formattedTime;
  };

  return (
  <>
    <Timeline>
      {(isFull || enabledCollapsedAll) && (
        <div className={classes.collapsable}>
          <Tooltip title={isCollapsedAll ? "Contraer todos":"Expandir todos"}>
            <SvgIcon 
              className={classes.icon} 
              component={isCollapsedAll ? collapseIcon : ExpandIcon}
              onClick={(e) => {
                for (let index = 0; index < isOpen.length; index++) {
                  isOpen[index] = !isCollapsedAll;
                }
                setIsCollapsedAll(!isCollapsedAll);
                setIsOpen([...isOpen]);
              }}
              />
          </Tooltip>
        </div>
      )}
      {timeLineData.map((data, index, array) => {
        let dotVariant =
          data.sequence_ === 1 || data.status_ == 0 ? "default" : "outlined";
        let dotColor = data.status_ == 0 ? "secondary" : "grey";
        let iconColor = data.status_ == 0 ? "white" : "green";
        let lastIndex = array.length - 1;

        const { date, time } = parseDateTime(data.createdAt);
        const formattedTime = convertTime(time);
        const nextItem = array[index + 1];
        const isNextItemError = nextItem && nextItem.status_ == 0;
        const connectorVariant = isNextItemError
          ? classes.errorTail
          : classes.successTail;
        isOpen.push(false);

        return (
          <div key={data.sequence_}>
            <TimelineItem>
              <TimelineOppositeContent className={classes.opposite}>
                <Typography variant="body2">{data.typeName_}</Typography>
                <Typography className={classes.oppositeTime}>
                  {date}
                  <br />
                  {formattedTime}
                </Typography>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot color={dotColor} variant={dotVariant}>
                  <MiscellaneousServicesIcon
                    htmlColor={iconColor}
                    onClick={toggle(index)}
                  />
                </TimelineDot>
                {index !== lastIndex && (
                  <TimelineConnector className={`${connectorVariant}`} />
                )}
              </TimelineSeparator>
              <TimelineContent>
                {data.status_ == 0 ? (
                  <Paper elevation={0} className={classes.paper}>
                    <Typography>{data.typeEventName}</Typography>
                    <Typography>Error</Typography>
                    <Typography>{data.codeEvent}</Typography>
                    <Typography className={classes.text}>
                      {data.description_.substring(0, isFull? 500:20)}
                      {data.description_.length > (isFull? 500:20) && (
                      <a
                        className={classes.link}
                        href="#"
                        onClick={(e) => {
                          setConfirmInactivate({
                            open: true
                            , message: [
                              {label: "Nombre", value: data.typeEventName}
                              , {label: "Código", value: data.codeEvent}
                              , {label: "Mensaje", value: data.description_, collapsed:true, style: true}
                            ]
                          });
                        }}
                      >
                        {" ... más"}
                      </a>)}
                    </Typography>
                  </Paper>
                ) : (
                  <Accordion
                    expanded={isOpen[index]}
                    style={
                      isOpen[index]
                        ? { border: "1px solid black" }
                        : {}
                    }
                  >
                    <AccordionSummary onClick={toggle(index)}>
                      <Typography>{data.typeEventName}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>{data.codeEventName}</Typography>
                      <Typography className={classes.text}>
                        {data.description_.substring(0, isFull? 500:20)}
                        {data.description_.length > (isFull? 500:20) && (
                        <a
                          className={classes.link}
                          href="#"
                          onClick={(e) => {
                            setConfirmInactivate({
                              open: true
                              , message: [
                                {label: "Nombre", value: data.typeEventName}
                                , {label: "Código", value: data.codeEvent}
                                , {label: "Mensaje", value: data.description_, collapsed:true, style: true}
                              ]
                            });
                          }}
                        >
                          {" ... más"}
                        </a>)}
                    </Typography>
                    </AccordionDetails>
                  </Accordion>
                )}
              </TimelineContent>
            </TimelineItem>
          </div>
        );
      })}
    </Timeline>
    {!!confirmInactivate && confirmInactivate.open && (
      <AlertConfirm
        isOpen={confirmInactivate.open}
        maxWidth="md"
        modalTittle="Evento"
        modalMessageHtml={confirmInactivate.message.map(lb=>(
          <Grid conatiner style={{color: "#575756", textAlign: "justify"}}>
            <Grid item xs={12}>
              <Typography style={{ fontWeight: 700, margin: "2px" }}>
                {lb.label}
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ margin: "2px", display: "flex", border: (lb.style ? "2px solid #b2b2b2":""), borderRadius:(lb.style ? "5px":""), padding:(lb.style ? "10px":""), background:(lb.style ? "rgba(0, 0, 0, 0.08)":"") }}>
              <Typography className={classes.text} style={{ margin: "2px" }}>
                {lb.collapsed? (
                  (lb.value.length ? lb.value : "-").substring(0, 500)
                ) : (
                  lb.value
                )}
                {lb.value.length > 500 && (
                  <a
                    className={classes.link}
                    href="#"
                    onClick={(e) => {
                      lb.collapsed = !lb.collapsed;
                      setConfirmInactivate({...confirmInactivate});
                    }}
                  >
                    {lb.collapsed ? " ... más" : " ... menos"}
                  </a>
                )}
              </Typography>
              {lb.value?.length > 100 && (
                <Tooltip title="Copiar">
                  <SvgIcon
                    style={{ cursor: "pointer" }}
                    component={CopyIcon}
                    onClick={(ev) => {
                      navigator.clipboard.writeText(lb.value);
                    }}
                  />
                </Tooltip>
              )}
            </Grid>
            <Divider style={{ height: "1px", width: "100%" }} />
          </Grid>
        ))}
        showBtnAccept={true}
        showBtnCancel={false}
        showBtnClose={true}
        textButtonAccept="Aceptar"
        handleAccept={() => {
          setConfirmInactivate({
            open: false,
            message: []
          });
        }}
        onClose={() => {
          setConfirmInactivate({
            open: false,
            message: []
          });
        }}
      />
    )}
  </>
  );
};

export default TransactionTimeline;
