import { Card, Grid, makeStyles } from "@material-ui/core";
import React from "react";
import AlertConfirm from "../alertConfirm.component";
import FullLoader from "../Loader/FullLoader.component";
import PageBackground from "../pageBackground.component";
import IconEspecialButton from "../ButtonForm/IconEspecialButton.component";
import { Lock } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: "28px",
    fontWeight: "bold",
    color: theme.palette.primary.dark,
    padding: "10px 0px 10px 25px",
    // width: "97%",
  },
  externalCard: {
    marginTop: 40,
    marginBottom: 40,
    paddingBottom: 40,
    borderRadius: "10px",
    justifyContent: "center",
    opacity: 1,
    width: "95%",
    minWidth: "95% !important",
    boxShadow: "0px 0px 5px 0px #a7a6a6",
    background:
      "transparent linear-gradient(180deg, #FFFFFFCC 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box",
  },
  contentCard: (props) => ({
    margin: "10px 25px",
    borderRadius: "10px",
    background: theme.palette.common.white,
    width: "98%",
    minWidth: "93% !important",
    border: `2px solid ${theme.palette.primary.light}`,
  }),
  card: {
    borderRadius: "10px",
    background: theme.palette.common.white,
  },
  contentPage: {
    display: "flex",
    justifyContent: "center",
    //alignItems: "center",
    flexDirection: "column",
  },
}));
const ViewLayout = ({
  headerTitle,
  children,
  confirmInactivate,
  setConfirmInactivate,
  handleAccept,
  handleConfirmClose,
  isLoading,
  parentId,
  visibleIcon,
  visibleIconPath,
  moreOptions,
  moreOptionsName,
  moreOptionsHandle
}) => {
  const classes = useStyles();
  const handleOpen = () => {
    setConfirmInactivate({
      ...confirmInactivate,
      open: false,
    });
  };

  return (
    <>
    {parentId && (<> {children} </>)}
    <div className={classes.contentPage}>
      <PageBackground></PageBackground>
      <div className={classes.externalCard}>
        {!parentId && (
        <>
          {/* <div className={classes.title}>{headerTitle}</div> */}
          <Grid container justifyContent="space-between">
            <Grid className={classes.title}>{headerTitle}</Grid>
            {visibleIcon && (
            <Grid style={{ marginRight: "25px", paddingTop: "10px" }}>
              {moreOptions && (
                <IconEspecialButton
                  aria-controls="customized-menu"
                  aria-haspopup="true"
                  variant="contained"
                  size={"small"}
                  onClick={moreOptionsHandle}
                  startIcon={<Lock />}
                >
                  {moreOptionsName ?? "Avanzado"}
                </IconEspecialButton>
              )}
              <img src={visibleIconPath ?? "../monithor.png"} alt="Logo" style={{ width: "50px" }} />
            </Grid>
            )}
          </Grid>
          <Grid container justifyContent="center">
            <Grid container item lg={12} md={12} sm={12}>
              <div className={classes.contentCard}>
                <Card
                  className={`${classes.card} 'User-Card' ${
                    true ? classes.showCardTrue : classes.showCardFalse
                  }`}
                >
                  {isLoading ? <FullLoader open={isLoading} /> : null}
                  {children}
                </Card>
              </div>
            </Grid>
          </Grid>
        </>
        )}
        {(confirmInactivate !== undefined && confirmInactivate.open) && (
          <AlertConfirm
            isOpen={confirmInactivate.open}
            setOpen={handleOpen}
            customFunction={confirmInactivate.customFunction}
            modalMessage={confirmInactivate.message}
            handleAccept={
              !!confirmInactivate.handleAccept
                ? confirmInactivate.handleAccept
                : handleAccept
            }
            showBtnAccept={confirmInactivate.showBtnAccept}
            showBtnCancel={confirmInactivate.showBtnCancel}
            showBtnClose={false}
            textButtonAccept={confirmInactivate.textButtonAccept}
            textButtonCancel="No"
            onClose={() => {
              if (handleConfirmClose === undefined) {
                setConfirmInactivate({
                  open: false,
                  checked: false,
                });
              } else {
                handleConfirmClose();
              }
            }}
          />
        )}
      </div>
    </div>
    </>
  );
};

export default ViewLayout;
