import React from 'react'
import { Redirect, Route } from 'react-router-dom'

export const PublicRoute = ({component: Component,isAuth,isEmbedded,...rest}) => {
    if(isEmbedded){
        const url = document.referrer;
        const domainRegex = /^(?:https?:\/\/)?(?:[^@\/\n]+@)?(?:www\.)?([^:\/\n]+)/;
        const domain = url.length ? url.match(domainRegex) : [""];

        if(domain.length &&
            !(domain[0].includes("parameta.co") ||
            domain[0].includes("coupahost.com"))){
            return <Redirect to={{ pathname: "/login" }} />;
        }
    }

    return (
        <Route
            {...rest}
            render={ props => 
            !isAuth ? (
                <Component {...props} />
            ) : (
                <Redirect to="/" />
            )
            }
        />
    )
}
