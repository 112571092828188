import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { getMenu, GetMenuByUser } from "../../actions/menu.action";
import { redirectToLogin, revokeTokenUsers } from "../../actions/auth.action";
import { getEnterpriseByID } from "../../actions/enterprise.action";
import { invokeInterceptor } from "../../utils/UnauthorizedResponseInterceptor";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import HelpBar from "../AppBarMenu/helpBar.component";
import BuyBar from "../AppBarMenu/buyBar.component";
import MenuIcon from "@mui/icons-material/Menu";
import Tooltip from "@mui/material/Tooltip";
import SvgIcon from "@mui/material/SvgIcon";

import CarBuyBar from "../AppBarMenu/carBuyBar.component copy";
import { parseJwt } from "../../utils/userInfo";
import { isEmpty } from "../../utils/proprietaryHooks";
import AlertConfirm from "../../components/alertConfirm.component";
import PageBackground from "../pageBackground.component";
import {
  LeftMenu,
  // SuiteBar,
  UserMenu,
} from "@pif-tr-components/components";

import { Avatar, CircularProgress } from "@material-ui/core";
//import { useLocation } from "react-router";
import { IS_REFRESH_TOKEN } from "../../actions/types";
// import { Grid } from  '@material-ui/core';

//let collapsedMenu = 240;

const MasterPage = ({
  component: Component,
  container,
  appName,
  isEmbedded,
  ...props
}) => {
  const [collapsedMenu, setCollapsedMenu] = useState({
    drawerWidth: 245,
    collapsed: false,
  });
  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      //justifyContent: "center",
    },
    margin: {
      margin: theme.spacing(1),
    },
    drawer: {
      [theme.breakpoints.up("sm")]: {
        width: collapsedMenu.drawerWidth,
        flexShrink: 0,
      },
      marginRight: 10,
      zIndex: 3,
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      [theme.breakpoints.up("sm")]: {
        width: "100%",
      },
      paddingLeft: "10px",
      paddingRight: "10px",
      background: "#F1F1F1 0% 0% no-repeat padding-box",
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      padding: "0px",
      justifyContent: "flex-start",
      overFlowX: "hidden",
      width: "100%",
    },
    imageApp: {
      width: "163px",
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
      height: "43px",
      backgroundImage: theme.logo.backgroundImage,
      paddingTop: "14px",
      flex: 1,
    },
    userName: {
      fontSize: "14px",
      fontWeight: "bold",
      textAlign: "right",
      paddingRight: "8px",
      color: "#6D6E71",
      textTransform: "capitalize",
      alignItems: "center",
      display: "flex",
    },
    contentMenu: {
      flexDirection: "row",
      display: "flex",
      width: "85%",
      justifyContent: "flex-end",
    },
    iconGroup: {
      display: "flex",
      width: "400px",
      justifyContent: "flex-end",
    },
    constenAvatarName: {
      display: "flex",
      width: "auto",
      marginLeft: 10,
    },
    icon: {
      cursor: "pointer",
    },
    menuIcon: {
      zIndex: 4,
      position: "absolute",
      color: "rgb(109, 110, 113)",
      left: collapsedMenu.drawerWidth ? collapsedMenu.drawerWidth - 35 : 0,
      top: 70,
    },
  }));

  const classes = useStyles();
  //const location = useLocation();
  const [token, setToken] = useState({
    result: {
      token: "",
    },
    error: {},
  });
  const currentAppName = "PIF";
  const [privileges, setPrivileges] = useState([]);
  const {
    history,
    privilegesAuthResponse,
    MessageRefreshToken,
    isRefreshToken,
    revokeTokenUsers,
  } = props;

  const [confirmMessage, setConfirmMessage] = useState({
    message: "",
    item: "",
    open: false,
  });

  const [isLoading, setLoading] = useState(true);
  const [user, setUser] = useState({ name: "", surname: "" });
  const userInfo = {
    user: {
      enterprise: "true",
      email: "",
      name: "",
      role: [""],
      profilePicture: "",
      closeSession: () => {
        closeSession();
      },
    },
  };
  const [changedLeftMenu, setChangedLeftMenu] = useState(false);
  const dispatch = useDispatch();

  //const appName = this.props.appName;
  invokeInterceptor(appName, history, dispatch);

  useEffect(() => {
    // setIsLoadingToken(true);
    getToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isEmpty(MessageRefreshToken)) {
      setConfirmMessage({
        open: true,
        message: MessageRefreshToken,
        item: "RefreshTokenRevoked",
        textButtonAccept: "Aceptar",
      });
    }
  }, [MessageRefreshToken]);

  useEffect(() => {
    if (isRefreshToken) {
      // setIsLoadingToken(true);
      getToken();
      dispatch({
        type: IS_REFRESH_TOKEN,
        payload: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefreshToken]);

  useEffect(() => {
    if (!isEmpty(privilegesAuthResponse)) {
      setConfirmMessage({
        open: true,
        message: privilegesAuthResponse,
        item: "auth",
        textButtonAccept: "Aceptar",
      });
    }
  }, [privilegesAuthResponse]);

  useEffect(() => {
    const tokenLocal = localStorage.getItem("jwtToken");
    if (tokenLocal) {
      const tokenClear = tokenLocal.replace("Bearer ", "");
      if (token.result) {
        if (token.result.token && token.result.token !== "") {
          if (tokenClear !== token.result.token) {
            localStorage.setItem("jwtToken", "Bearer " + token.result.token);
            localStorage.setItem("refreshToken", token.result.refreshToken);
            getToken(token.result.token);
            setChangedLeftMenu(true);
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token.result.token]);

  useEffect(() => {
    if (changedLeftMenu) {
      setChangedLeftMenu(false);
    }
  }, [changedLeftMenu]);

  const getToken = (currentToken) => {
    let jwt;
    if (currentToken) {
      jwt = currentToken;
    } else {
      jwt = localStorage.getItem("jwtToken");
    }

    if (jwt) {
      const jwtToken = jwt.replace("Bearer ", "");
      const jwtPayload = parseJwt(jwt);
      setPrivileges(jwtPayload.PIF_PRIVILEGE);

      setUser({
        name: unescape(jwtPayload.given_name),
        surname: unescape(jwtPayload.family_name),
        enterprise: jwtPayload.customer_name,
        email: jwtPayload.email,
      });
      setToken((oldState) => ({
        ...oldState,
        result: {
          ...oldState.result,
          token: jwtToken,
        },
      }));

      setLoading(true);
      sleep(4000).then((r) => {
        dispatch(getEnterpriseByID(jwtPayload.customer_id));
        setLoading(false);
      });

      // setIsLoadingToken(false);
      return true;
    } else {
      setTimeout(getToken, 1000);
      return false;
    }
  };

  const sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };

  const closeSession = () => {
    revokeTokenUsers();
  };
  const handleMenuClick = () => {
    setCollapsedMenu({
      drawerWidth: collapsedMenu.collapsed ? 245 : 0,
      collapsed: !collapsedMenu.collapsed,
    });
  };

  if (isEmbedded) {
    return (
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {token.result.token && user.name? (
          <Component
            user={user}
            isLoading={isLoading}
            Loading={setLoading}
            privileges={privileges}
            {...props}
          />
        ) : (
          <PageBackground />
        )}
      </main>
    );
  } else {
    return (
      <div className={classes.root}>
        <CssBaseline />
        <AppBar position="fixed" className={`${classes.appBar} 'App-Bar'`}>
          <Toolbar>
            <div>
              <div className={classes.imageApp}></div>
            </div>
            <div className={classes.contentMenu}>
              <div className={classes.iconGroup}>
                {true ? (
                  <div></div>
                ) : (
                  <div>
                    {" "}
                    <CarBuyBar></CarBuyBar>
                    <BuyBar></BuyBar>
                    <HelpBar></HelpBar>
                  </div>
                )}
                {/* <Grid>
                <SuiteBar appName="PIF" token={token.result.token} />
              </Grid> */}
                <div className={classes.constenAvatarName}>
                  <Typography
                    component={"span"}
                    className={classes.userName}
                    noWrap
                  >
                    {`${user.name} ${user.surname}`} <br />
                    {`${user.enterprise}`}
                  </Typography>
                  {token.result.token ? (
                    <UserMenu
                      key={"useMenu"}
                      appName={currentAppName}
                      userInfo={userInfo}
                      setTokens={setToken}
                      tokens={token}
                      functions={[]}
                      viewLoader={true}
                    />
                  ) : (
                    <Avatar>
                      {" "}
                      <CircularProgress color="secondary" size={20} />{" "}
                    </Avatar>
                  )}
                </div>

                {/* <NotificationBar></NotificationBar> */}
              </div>
            </div>
          </Toolbar>
        </AppBar>
        {/* <Hidden xsUpDown> */}
        <div className={classes.menuIcon}>
          <Tooltip title="Menú">
            <SvgIcon
              className={classes.icon}
              component={MenuIcon}
              onClick={handleMenuClick}
            />
          </Tooltip>
        </div>
        {/* </Hidden> */}
        <nav
          className={`${classes.drawer} 'custom-drawer' `}
          aria-label="mailbox folders"
        >
          {token.result.token && !changedLeftMenu ? (
            <div>
              {/* <Hidden xsDown> */}
              <LeftMenu
                //style="width:60px"
                open={true}
                token={token.result.token}
                appName={currentAppName}
                drawerProps={{
                  variant: "persistent",
                  open: !collapsedMenu.collapsed,
                  //classes: classes.drawer,
                }}
                version="1.0.0"
                functions={[]}
              />
              {/* </Hidden> */}
            </div>
          ) : null}
        </nav>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          {token.result.token ? (
            <Component
              user={user}
              isLoading={isLoading}
              Loading={setLoading}
              privileges={privileges}
              {...props}
            />
          ) : (
            <PageBackground />
          )}
        </main>
        <div>
          <AlertConfirm
            isOpen={confirmMessage.open}
            modalMessage={confirmMessage.message}
            handleAccept={() => {
              if (confirmMessage.item === "sesion") {
                closeSession();
              } else if (confirmMessage.item === "RefreshTokenRevoked") {
                setConfirmMessage({
                  message: "",
                  item: "",
                  open: false,
                });

                closeSession();
              } else {
                ///cerrar load
                setConfirmMessage({
                  message: "",
                  item: "",
                  open: false,
                });

                //if (location.pathname === "/users") {
                //  history.go(0);
                // } else {
                //   history.push({
                //     pathname: "/users",
                //   });
                // }

                setLoading(false);

                dispatch({
                  type: "GET_PRIVILEGES_AUTH",
                  payload: {},
                });
              }
            }}
            showBtnAccept={true}
            showBtnCancel={false}
            textButtonAccept={confirmMessage.textButtonAccept}
            onCancel={true}
            onClose={() =>
              setConfirmMessage({
                message: "",
                item: "",
                open: false,
              })
            }
          />
        </div>
      </div>
    );
  }
};

MasterPage.propTypes = {
  getMenu: PropTypes.func.isRequired,
  container: PropTypes.any,
  //authReducer: PropTypes.func.isRequired,
  authReducer: PropTypes.any,
};

const mapStateToProps = (state) => ({
  menuReducer: state.menuReducer.getMenuResponse,
  getMenuByUserListResponse: state.menuReducer.getMenuByUserListResponse,
  isRevokeToken: state.authReducer.isRevokeToken,
  revokeTokenUsers: state.invoiceReducer,
  MessageRefreshToken: state.authReducer.MessageRefreshToken,
  privilegesAuthResponse: state.authReducer.privilegesAuthResponse,
  isRefreshToken: state.authReducer.isRefreshToken,
});

const mapDispatchToProps = {
  getMenu,
  redirectToLogin,
  revokeTokenUsers,
  GetMenuByUser,
  getEnterpriseByID,
};

export default connect(mapStateToProps, mapDispatchToProps)(MasterPage);
