
import { TOKEN_PREFIX } from "../config/config";

/**
 * assignAuthToken constant function (verify if token exists and set this to axios Authorization header and localStorage Token,
 * else, remove axios authorization header and localStorage Token)
 *
 * @export constant
 * @param {*} token
 * @returns boolean 
 */
export const assignAuthTokenAndRefreshToken = (tokenAndRefreshTokenJson) => {
  if (tokenAndRefreshTokenJson) {
    
    localStorage.setItem("jwtToken", TOKEN_PREFIX + tokenAndRefreshTokenJson.token);
    localStorage.setItem("refreshToken", tokenAndRefreshTokenJson.refreshToken);
    localStorage.setItem("encryptedToken",tokenAndRefreshTokenJson.encryptedToken);

    //If it is open from an iframe and it generated a SSO request
    if(window.opener){
      const token = tokenAndRefreshTokenJson.token;
      if(token && token.length && window.opener?.embebedSSOClick){
        window.opener.embebedSSOClick(tokenAndRefreshTokenJson);
        window.close();
      }
    }

  } else {
    localStorage.removeItem("jwtToken");
    localStorage.removeItem("refreshToken");
  }
};

export default assignAuthTokenAndRefreshToken;
