import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  makeStyles,
  Typography,
  TablePagination,
} from "@material-ui/core";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import ControlledInputRoundedForm from "../../components/InputForm/ControlledInputRoundedForm";
import ButtonSaveChanges from "../../components/ButtonForm/ButtonSaveChanges";
import CustomTable from "../../components/Table/CustomTable.component";
// import FullLoader from "../../components/Loader/FullLoader.component";
import ViewLayout from "../../components/layouts/ViewLayout";
import {FullLoader,  useDialog } from "@pif-tr-components/components";
import { getCountMaterials, getAllRecordsMaterials, exportExcelMaterials, setUrlMonithorService } from "../../actions/customviews.action";

export function CustomViewsMaterials({...props}) {
  const { user, privileges } = props;
  const [option, setOption] = useState(null);
  const [datatableMaterials, setDatatableMaterials] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [viewErrors, setViewErrors] = useState("");
  const [userPrivileges, setUserPrivileges] = useState({
    read: true,
    export: false
  })
  let params = new URLSearchParams(window.location.search);
  const readOnly = params.get("act") == "r";
  const [environmentURL, setEnvironmentURL] = useState(
    params.get("env") ? `${params.get("env")}` : ""
  );
  const [messageAlert] = useState({
      messageAlert: "Acción realizada correctamente",
      confirmText: "Aceptar",
    });

  const { getEnterpriseByIDResponse } = useSelector(
    (store) => store.enterpriseReducer
  );

  useEffect(() => {
    if (privileges) {
      setUserPrivileges({
        read: privileges.filter(p => p.includes(`CMI_MATERIALS_READ`)).length > 0,
        export: privileges.filter(p => p.includes(`CMI_MATERIALS_EXPORT`)).length > 0
      });
    }
  }, [privileges]);

  useEffect(() => {
    if (getEnterpriseByIDResponse.monitoringService) {
      setEnvironmentURL(getEnterpriseByIDResponse.monitoringService);
    }

    if (environmentURL) {
      setUrlMonithorService(environmentURL);
    }
  }, [getEnterpriseByIDResponse, environmentURL]);

  const useStyles = makeStyles((theme) => ({
    img: {
      width: "25px",
    },
    content: {
      flexGrow: 1,
      padding: "0px",
      justifyContent: "flex-start",
      overFlowX: "hidden",
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      [theme.breakpoints.up("sm")]: {
        width: "100%",
      },
      paddingLeft: "10px",
      paddingRight: "10px",
      background: "#e0e0e0 0% 0% no-repeat padding-box",
    },
    imageApp: {
      width: "150px",
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
      height: "43px",
      backgroundImage: theme.logo.backgroundImage,
      paddingTop: "14px",
      flex: 1,
    },
    toolbar: theme.mixins.toolbar,
    title: {
      color: theme.palette.primary.light,
      fontSize: "25px",
    },
  }));

  const classes = useStyles();
  const dispatch = useDispatch();
  const { getAllDataMaterials, getErrorAllDataMaterials, getCountAllRecordsMaterials, getResponseExportExcel } = useSelector(
    (store) => store.customViewsReducer
  );

  useEffect(() => {
    if (!!getErrorAllDataMaterials) {
      setIsLoading(false);
    }
  }, [getErrorAllDataMaterials]);

  useEffect(() => {
    if (getAllDataMaterials.state) {
      setIsLoading(false);
      setDatatableMaterials(getAllDataMaterials.data);
    }
  }, [getAllDataMaterials]);

  useEffect(() => {
    if (getCountAllRecordsMaterials !== 0) {
      setTotalRows(getCountAllRecordsMaterials);
    }
  }, [getCountAllRecordsMaterials]);

  useEffect(() => {
    if (getResponseExportExcel !== "") {
      // onOpen();
      informMessage(getResponseExportExcel);
    }
  }, [getResponseExportExcel]);

  const validationSchema = Yup.object().shape({
    idItemCoupa: Yup.string(),
    center: Yup.string(),
    centerEnd: Yup.string(),
    codeERP: Yup.string(),
    sapClient: Yup.string(),
    descriptionCoupa: Yup.string(),
    stock: Yup.string(),
    stockEnd: Yup.string(),
  });
  const { control, errors, getValues } = useForm({
    defaultValues: {
      idItemCoupa: "",
      center: "",
      centerEnd: "",
      codeERP: "",
      sapClient: "",
      descriptionCoupa: "",
      stock: "",
      stockEnd: "",
    },
    resolver: yupResolver(validationSchema),
    shouldUnregister: false,
    mode: "onChange",
  });

  const { Dialog, onOpen, onClose } = useDialog({
      bodyText: messageAlert.messageAlert,
      confirmationButtonText: messageAlert.confirmText,
      cancelButtonText: messageAlert.cancelText,
      onConfirmClick: messageAlert.handlerConfirm,
    });
  
    const informMessage = (msg) => {
      messageAlert.messageAlert = msg;
      messageAlert.confirmText = "Aceptar"
      messageAlert.cancelText = null;
      messageAlert.handlerConfirm = () => {
        onClose();
      };
      onOpen();
    };

  const searchData = (selfpage, selfRowPerPage, executeCount) => {
    const [isAllClear, dataSearch] = validateData();
    if (isAllClear) {
      setViewErrors("Debe llenar al menos un campo");
    } else {
      setIsLoading(true);
      if (executeCount) {
        dispatch(getCountMaterials(dataSearch));
      }
      const currentPage = selfpage !== undefined ? selfpage : page;
      const currentRowsPerPage =
        selfRowPerPage !== undefined ? selfRowPerPage : rowsPerPage;
      dispatch(
        getAllRecordsMaterials({
          ...dataSearch,
          rowPerPage: currentRowsPerPage,
          page: currentRowsPerPage * currentPage,
        })
      );
    }
  };

  const validateData = () => {
    let dataSearch = {};
    const data = getValues();
    let isAllClear = true;
    Object.keys(data).forEach((key) => {
      if (data[key] !== undefined && data[key] !== "") {
        dataSearch = { ...dataSearch, [key]: data[key] };
        isAllClear = false;
        setViewErrors("");
      }
    });
    return [isAllClear, dataSearch];
  };

  const columns = [
    {
      id: "type_resource",
      label: "Tipo",
      haveDropDown: true,
      align: "center",
      format: (value) => {
        return value.toLowerCase();
      },
    },
    {
      id: "idItemCoupa",
      label: "ID item Coupa",
      haveDropDown: true,
      align: "center",
    },
    {
      id: "item_number_sap",
      label: "ID item SAP",
      haveDropDown: true,
      align: "center",
    },
    {
      id: "descriptionItemCoupa",
      label: "Nombre",
      haveDropDown: true,
      align: "center",
    },
    {
      id: "sap_client",
      label: "Mandante",
      haveDropDown: true,
      align: "center",
    },
    {
      id: "logistics_center",
      label: "Centro",
      haveDropDown: true,
      align: "center",
    },
    {
      id: "stock",
      label: "Almacén",
      haveDropDown: true,
      align: "center",
    },
    {
      id: "status_item_sap",
      label: "Estado",
      haveDropDown: true,
      align: "center",
    },
  ];

  const getButtons = () => {
    let button = {
      // searchBar: {
      //   cellSize: {
      //     lg: 8,
      //     md: 8,
      //     sm: 11,
      //   },
      // },
      // menuOrder: {
      //   options: [
      //     {
      //       label: "Más reciente",
      //       columns: [
      //         {
      //           prop: "firstAttempt",
      //           direction: 1,
      //           format: (date) => {
      //             return new Date(date).getTime();
      //           },
      //         },
      //       ],
      //     },
      //     {
      //       label: "Más antiguo",
      //       columns: [
      //         {
      //           prop: "firstAttempt",
      //           direction: -1,
      //           format: (date) => {
      //             return new Date(date).getTime();
      //           },
      //         },
      //       ],
      //     },
      //   ],
      //   cellSize: {
      //     lg: 12,
      //     md: 12,
      //     sm: 12,
      //   },
      // },
    };
    return button;
  };

  const handleChangePage = (event, newPage) => {
    searchData(newPage, undefined, false);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const rowPerPage = parseInt(event.target.value, 10);
    searchData(undefined, rowPerPage, false);
    setRowsPerPage(rowPerPage);
    setPage(0);
  };

  const handleExportExcel = () => {
    dispatch(
      exportExcelMaterials({
        idItemCoupa: getValues().idItemCoupa,
        center: getValues().center,
        centerEnd: getValues().centerEnd,
        codeERP: getValues().codeERP,
        sapClient: getValues().sapClient,
        descriptionCoupa: getValues().descriptionCoupa,
        stock: getValues().stock,
        stockEnd: getValues().stockEnd,
        email: user?.email
      })
    );
  }

  return (
    <>
      <main className={classes.content}>
        {/* <div className={classes.toolbar} /> */}
        <ViewLayout 
          headerTitle={"Búsqueda de materiales / servicios en CMI"} 
          visibleIcon={true} 
          visibleIconPath={"../../../monithor.png"}
          moreOptions={!userPrivileges.export && !readOnly}
          moreOptionsHandle={(e) => {
              window.location = "/Login?appName=coupa";
            }
          }>
          <Grid container>
            <Grid
              container
              item
              lg={8}
              md={10}
              sm={12}
              xs={12}
              style={{ padding: "20px" }}
              spacing={2}
            >
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <ControlledInputRoundedForm
                  id="idItemCoupa"
                  name="idItemCoupa"
                  label="ID item coupa"
                  control={control}
                  fullWidth
                  error={errors.articleName}
                  helperText={errors.articleName?.message}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <ControlledInputRoundedForm
                  id="descriptionCoupa"
                  name="descriptionCoupa"
                  label="Nombre del artículo"
                  control={control}
                  fullWidth
                  error={errors.articleName}
                  helperText={errors.articleName?.message}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <ControlledInputRoundedForm
                  id="center"
                  name="center"
                  label="Centro"
                  control={control}
                  fullWidth
                  error={errors.center}
                  helperText={errors.center?.message}
                />
              </Grid>
              {/* <Grid item lg={6} md={6} sm={6} xs={12}>
                <ControlledInputRoundedForm
                  id="centerEnd"
                  name="center"
                  label="Centro final"
                  control={control}
                  fullWidth
                  error={errors.center}
                  helperText={errors.center?.message}
                />
              </Grid> */}
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <ControlledInputRoundedForm
                  id="codeERP"
                  name="codeERP"
                  label="Código SAP"
                  control={control}
                  fullWidth
                  error={errors.ERPCode}
                  helperText={errors.ERPCode?.message}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <ControlledInputRoundedForm
                  id="sapClient"
                  name="sapClient"
                  label="Mandante"
                  control={control}
                  fullWidth
                  error={errors.principal}
                  helperText={errors.principal?.message}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <ControlledInputRoundedForm
                  id="stock"
                  name="stock"
                  label="Almacén"
                  control={control}
                  fullWidth
                  error={errors.stock}
                  helperText={errors.stock?.message}
                />
              </Grid>
              {/* <Grid item lg={6} md={6} sm={6} xs={12}>
                <ControlledInputRoundedForm
                  id="stock"
                  name="stockEnd"
                  label="Almacén final"
                  control={control}
                  fullWidth
                  error={errors.stock}
                  helperText={errors.stock?.message}
                />
              </Grid> */}
              <Grid container item lg={12} md={12} sm={12} xs={12} spacing={2}>
                <Grid item>
                  <ButtonSaveChanges
                    title="Buscar"
                    handleClick={() => {
                      searchData(0, undefined, true);
                    }}
                  />
                </Grid>
                {userPrivileges.export && (
                  <Grid item>
                    <ButtonSaveChanges
                      title="Exportar"
                      handleClick={handleExportExcel}
                    />
                  </Grid>
                )}
                <Grid item>
                  <ButtonSaveChanges
                    title="Ir auraportal"
                    handleClick={(e) => {
                      window.open(
                        "https://auraportal.cmi.co/Home/Home.aspx",
                        "_blank"
                      );
                    }}
                  />
                </Grid>
                <Grid item>
                  <ButtonSaveChanges
                    title="Powerapps"
                    handleClick={(e) => {
                      window.open(
                        "https://apps.powerapps.com/play/9e62811b-c1d8-494c-9121-9d92a649d377?tenantId=42ced7fa[…]enColor=rgba%28252%2C+147%2C+0%2C+1%29&skipAppMetadata=true",
                        "_blank"
                      );
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item lg={12} md={12} sm={12}>
                {viewErrors !== "" && (
                  <div className={classes.errorText}>
                    <Typography color="error" variant="subtitle2">
                      {viewErrors}
                    </Typography>
                  </div>
                )}
              </Grid>
            </Grid>
          </Grid>

          <CustomTable
            columns={columns}
            mainParam={"name"}
            data={datatableMaterials}
            buttons={getButtons()}
            option={option}
            setOption={setOption}
            paramToDisable={"idstate"}
            havePagination={datatableMaterials.length > 10 ? true : false}
          />
          {totalRows > 10 ? (
            <Grid>
              <TablePagination
                component="div"
                count={totalRows}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[10, 20, 30]}
              />
            </Grid>
          ) : (
            <></>
          )}
        </ViewLayout>
        <FullLoader open={isLoading} />
        <Dialog />
      </main>
    </>
  );
}
