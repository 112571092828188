import { alpha, makeStyles, TextField } from '@material-ui/core';
import { useState, useEffect, useRef } from 'react'
import { Controller } from 'react-hook-form';

import RemoveIcon from "@mui/icons-material/DeleteOutline";
import DetailIcon from "@mui/icons-material/Reorder";
import EditIcon from "@mui/icons-material/EditOutlined";
import SaveIcon from "@mui/icons-material/SaveOutlined";
import Tooltip from '@mui/material/Tooltip';
import SvgIcon from "@mui/material/SvgIcon";
import Hover from './Hover';

const useStyles = makeStyles((theme) => ({
    root: {
        border: "1px solid #e2e2e1",
        overflow: "hidden",
        borderRadius: 10,
        //height: '43px',
        backgroundColor: "#FFFFFF",
        boxShadow: `0px 1px 1px #00000026`,
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        "&:hover": {
            backgroundColor: "#fff",
        },
        '&$focused': {
            backgroundColor: '#fff',
            boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
            borderColor: theme.palette.primary.main,
        },
        "&$error": {
            borderColor: theme.palette.error.main,
        },
        "& .MuiInputBase-input.Mui-error": {
            borderColor: theme.palette.error.main,
        },
        "& .MuiInputBase-input.Mui-disabled": {
            backgroundColor: "#fff",
        },
        "&.MuiFilledInput-root.Mui-disabled": {
            backgroundColor: "#fff !important",
        },
    },
    focused: {
        fontSize: 14,
    },
    error: {},
    inputHiddenLabel: {
        marginTop: "1px",
        color: "#b2b2b2",
    },
}));

const ControlledInputRoundedForm = ({ focused, inputProps, inputRef, stateShrink, setStateShrink, adornment, control, pattern, isCustomizable, detailOption, handleDetailClick, editOption, handleEditClick, deleteOption, handleRemoveClick, saveOption, handleSaveClick, customOption, customTitle, customIcon, handleCustomClick, ...props }) => {
    const classes = useStyles();
    const regexPattern = new RegExp(pattern);

    const handleSpecialCharacters = (text) => {
        if (pattern === undefined) {
            validateShrink(text);
            return text;
        }
        let value = text.replace(regexPattern, "");
        validateShrink(value);
        return value;
    };

    const validateShrink = (text) => {
        if (text?.length === 0) {
            if (typeof setStateShrink === "function") {
                setStateShrink((state) => ({ ...state, [`${props.name}`]: false }));
            }
        } else {
            if (typeof setStateShrink === "function") {
                setStateShrink((state) => ({ ...state, [`${props.name}`]: true }));
            }
        }
    };
    if(!!inputProps)
        Object.assign(inputProps,{ readOnly: !!props.disabled });
    else
        inputProps = { readOnly: !!props.disabled };
    props.disabled = false;

    const controlledInput = (
        <Controller
            control={control}
            name={props.name}
            {...props}
            render={({ onChange, value, ...rest }) => (
                <TextField
                    variant="filled"
                    size="small"
                    margin={"dense"}
                    InputProps={{ classes, disableUnderline: true, inputProps, ...adornment }}
                    InputLabelProps={{
                        classes: {
                            root: classes.inputHiddenLabel,
                            focused: classes.focused,
                        },
                        shrink: !!setStateShrink ? stateShrink : props.shrink,
                        focused: focused
                    }}
                    inputRef={inputRef}
                    value={value}
                    onChange={(e) => {
                        onChange(props.type==="number" ? Number(e.target.value) : handleSpecialCharacters(e.target.value));
                    }}
                    {...rest}
                    {...props}
                />
            )}
        />
    );

    const [editAction, setEditAction] = useState(true);
    const editClick = (event) =>{
        setEditAction(false);
        if(handleEditClick){
            handleEditClick(event);
        }
    }

    const saveClick = (event) =>{
        setEditAction(true);
        if(handleSaveClick){
            handleSaveClick(event);
        }
    }

    const controlledInputCustomizable = (
        <Hover>
              {(hover) => (
                <>
                    {controlledInput}
                    {isCustomizable && hover &&
                    <div style={{height:"51px", marginTop:"-55px", position:"relative", paddingTop:"3px", marginBottom:"4px", width:"30px", float:"right"}}>
                        {detailOption && <Hover>
                            {(detailHover) => (
                                <Tooltip title="ver detalle">
                                <SvgIcon 
                                    style={{cursor:"pointer", float: "right", color: detailHover ? '#da163d' : '#575756'}}
                                    onClick={handleDetailClick}
                                    component={DetailIcon}/>
                                </Tooltip >   
                            )}
                        </Hover>}
                        {deleteOption && <Hover>
                            {(deleteHover) => (
                                <Tooltip title="Quitar">
                                <SvgIcon 
                                    style={{cursor:"pointer", float: "right", color: deleteHover ? '#da163d' : '#575756'}}
                                    onClick={handleRemoveClick}
                                    component={RemoveIcon}/>
                                </Tooltip >   
                            )}
                        </Hover>}
                        {editAction && editOption && <Hover>
                            {(editHover) => (
                                <Tooltip title="Editar">
                                <SvgIcon 
                                    style={{cursor:"pointer", float: "right", color: editHover ? '#da163d' : '#575756'}}
                                    onClick={saveOption ? editClick : handleEditClick}
                                    component={EditIcon}/>
                                </Tooltip >   
                            )}
                        </Hover>}
                        {!editAction && saveOption && <Hover>
                            {(saveHover) => (
                                <Tooltip title="Guardar">
                                <SvgIcon 
                                    style={{cursor:"pointer", float: "right", color: saveHover ? '#da163d' : '#575756'}}
                                    onClick={saveClick}
                                    component={SaveIcon}/>
                                </Tooltip >   
                            )}
                        </Hover>}
                        {customOption && <Hover>
                            {(customHover) => (
                                <Tooltip title={customTitle ?? "Procesar"}>
                                <SvgIcon 
                                    style={{cursor:"pointer", float: "right", color: customHover ? '#da163d' : '#575756'}}
                                    onClick={handleCustomClick}
                                    component={customIcon}/>
                                </Tooltip >   
                            )}
                        </Hover>}
                    </div>}
                </>
            )}
        </Hover>
    );

    const input = useRef(null);
    inputRef = inputRef??input;
    useEffect(() => {
        if (inputRef.current && focused) {
            inputRef.current.focus();
        }
    });
    
    return isCustomizable ? controlledInputCustomizable : controlledInput;
};

export default ControlledInputRoundedForm;
