import axios from "axios";
import {
  API_AUTHENTICATION,
  API_SAML_AUTHENTICATION,
  encryptionKey,
  encryptionIv,
  TIME_REFRESH_TOKEN,
  TOKEN_PREFIX
} from "../config/config";
import {
  SET_CURRENT_USER,
  LOADING,
  DEFAULTRESPONSE,
  GENERATE_TOKENS,
  REVOKE_TOKENS,
  SET_IS_AUTHENTICATED,
  GET_ERRORS,
  GET_USER_DATA,
  IS_INVALID_TOKEN,
  SEND_ACTIVATION_EMAIL,
  IS_LOGIN_AUTOMATIC,
  GET_RESPONSE_RESET_PASSWORD,
  GET_ERROR_RESET_PASSWORD,
} from "./types";
import jwt_decode from "jwt-decode";
import assignAuthTokenAndRefreshToken from "../utils/assignAuthToken";

import aesjs from "aes-js";
import moment from "moment";
import "moment/locale/es";
import { getBrowserInfo } from "../utils/proprietaryHooks";

// const userLoginInfo = async (data) => {
//   const response = await axios.put(API_AUTHENTICATION + "/api/UserLoginInfo", data)
//   if (response.data.statusCode === 200) {
//     return true;
//   } else {
//     return false;
//   }
// };

const awaitTimeout = (delay, reason) =>
  new Promise((resolve, reject) =>
    setTimeout(() => (reason === undefined ? resolve() : reject(reason)), delay)
  );
const wrapPromise = (promise, delay, reason) =>
  Promise.race([promise, awaitTimeout(delay, reason)]);

export /**
 * AuthAction loginUser ( set user credentials to server for login attempt )
 *
 * @method POST
 * @param {*} userData
 */
const loginUser = (userData, history) => async (dispatch) => {
  wrapPromise(axios.get("https://api.ipify.org?format=json"), 4000, {
    reason: "get IP timeout",
  })
    .then((data) => {
      console.log(data.data);
      const ip = data?.data?.ip;
      dispatch(loginUserWithIp(userData, history, ip ?? "0.0.0.1"));
    })
    .catch((data) => {
      console.log(`Failed with reason: ${data.reason}`);
      dispatch(loginUserWithIp(userData, history, "0.0.0.1"));
    });
};

export /**
 * AuthAction loginUserWithIp ( set user credentials to server for login attempt )
 *
 * @method POST
 * @param {history} history
 */
const loginUserWithIp = (userData, history, ip) => async (dispatch) => {
  let datadispatch = {};
  changeLoading(dispatch, true);
  const infoNavigator = getBrowserInfo();
  //let ip = "0.0.0.1";
  //const responseIP = await axios.get("https://api.ipify.org?format=json");
  //const ip = responseIP.data.ip;
  if (ip && infoNavigator) {
    await axios
      .post(API_AUTHENTICATION + "/api/Login", {
        ...userData,
        ip: ip,
        Browser: infoNavigator,
        TypeDeviceId: 2,
      })
      .then(async (response) => {
        const { data } = response;
        if (data.statusCode === 200) {
          const { urlApp } = data.result;
          if (urlApp === undefined) {
            console.error(
              "Ha ocurrido un error. No se encuentra definida la url a redireccionar."
            );
          } else {
            //window.location.assign(urlApp);
            dispatch({
              type: SET_IS_AUTHENTICATED,
              payload: true,
            });
          }
        }
        datadispatch = authResponse2(
          response.status,
          data.statusCode,
          "A10",
          0,
          data.result.information,
          data.result.urlApp,
          data.result.informationCode,
          data.result.ententerpriseList,
          data.result.token,
          data.result.failedLoginInformation
        );
      })
      .catch((error) => {
        const { response } = error;
        if (response) {
          try {
            const { data } = response;
            const { informationCode, information } = data.result;
            if (informationCode === "R14") {
              //Se debe cambiar la clave antes de su primer acceso
              if (history) {
                history.push({
                  pathname: "/Alert",
                  state: {
                    message: !!information
                      ? information
                      : "Por favor revise el correo de bienvenida que contiene el enlace y las instrucciones para activación de su usuario.",
                    pagename: "/Login",
                  },
                });
              }
            } else if (informationCode === "R18") {
              //Se debe cambiar la clave Se encuentra vencida
              if (history) {
                history.push({
                  pathname: "/Alert",
                  state: {
                    message: !!information
                      ? information
                      : "Su clave se encuentra venciada debe realizar el cambio.",
                    pagename: data.result.urlApp,
                  },
                });
              }
            } else {
              //Se retorna el mensaje
              datadispatch = authResponse(
                response.status,
                !!data.statusCode ? data.statusCode : "500",
                data.result.informationCode,
                data.result.blockingtime,
                !!data.result.information && data.statusCode !== 500
                  ? data.result.information
                  : DEFAULTRESPONSE.noResponseFromApi,
                data.result.failedLoginInformation
              );
            }
          } catch {
            datadispatch = authResponse(
              500,
              500,
              "",
              0,
              DEFAULTRESPONSE.noResponseFromApi
            );
          }
        } else {
          datadispatch = authResponse(
            500,
            500,
            "",
            0,
            DEFAULTRESPONSE.noResponseFromApi
          );
        }
      })
      .then(function () {
        changeLoading(dispatch, false);
        dispatch({
          type: SET_CURRENT_USER,
          payload: datadispatch,
        });
      });
  }
};

export /**
 * AuthAction resetPassword ( Reset Password  )
 *
 * @method POST
 * @param {history} history
 */
const resetPassword = (userData, history) => async (dispatch) => {
  let datadispatch = {};
  changeLoading(dispatch, true);
  await axios
    .post(API_AUTHENTICATION + "/api/login/ResetPassword", userData)
    .then((response) => {
      const { data } = response;
      if (history)
        history.push({
          pathname: "/Alert",
          state: {
            message: data.result.information,
            pagename: "/Login",
          },
        });
    })
    .catch((error) => {
      const { response } = error;
      if (response) {
        try {
          const { data } = response;

          datadispatch = authResponse(
            response.status,
            !!data.statusCode ? data.statusCode : "500",
            data.result.informationCode,
            data.result.blockingtime,
            !!data.result.information && data.statusCode !== 500
              ? data.result.information
              : DEFAULTRESPONSE.noResponseFromApi,
            data.result.failedLoginInformation
          );
        } catch {
          datadispatch = authResponse(
            500,
            500,
            "",
            0,
            DEFAULTRESPONSE.noResponseFromApi
          );
        }
      } else {
        datadispatch = authResponse(
          500,
          500,
          "",
          0,
          DEFAULTRESPONSE.noResponseFromApi
        );
      }
    })
    .then(function () {
      changeLoading(dispatch, false);
      dispatch({
        type: SET_CURRENT_USER,
        payload: datadispatch,
      });
    });
};

export /**
 * Role Action create ( create )
 *
 * @method POST
 * @param {history} history
 */
const resetPasswordForm = (userData) => async (dispatch) => {
  await axios
    .post(API_AUTHENTICATION + "/api/login/ResetPassword", userData)
    .then((response) => {
      if (response !== undefined) {
        if (response.status === 200) {
          dispatch({
            type: GET_RESPONSE_RESET_PASSWORD,
            payload: response.data.result.information,
          });
        } else {
          dispatch({
            type: GET_ERROR_RESET_PASSWORD,
            payload: response.data,
          });
        }
      }
    })
    .catch((err) => {
      console.error(err);
      const response = !!err.response
        ? err.response.data
        : DEFAULTRESPONSE.noResponseFromApi;
      dispatch({
        type: GET_ERROR_RESET_PASSWORD,
        payload: response,
      });
    });
};

export /**
 * AuthAction assignPassword ( assign Password  )
 *
 * @method POST
 * @param {history} history
 */
const assignPassword = (userData, history) => async (dispatch) => {
  let datadispatch = {};
  changeLoading(dispatch, true);
  let isGoHome = false;
  await axios
    .post(API_AUTHENTICATION + "/api/login/AssignPassword", userData)
    .then((response) => {
      const { data } = response;
      if (data.result.apps !== null) {
        if (history)
          history.push({
            pathname: "/availableapps",
            state: {
              message: data.result.information,
              apps: data.result.apps,
              userData: {
                appname: userData.appname,
                page: "",
                password: userData.password,
                user: userData.user,
              },
            },
          });
      } else {
        if (data.result.mechanismAfterSetPass === 1) {
          if (history) {
            history.push({
              pathname: "/Alert",
              state: {
                message: data.result.information,
                pagename: "/Login",
              },
            });
          }
        } else {
          if (history) {
            isGoHome = true;
            dispatch({
              type: IS_LOGIN_AUTOMATIC,
              payload: true,
            });
            dispatch(
              loginUser(
                {
                  appname: userData.appname,
                  page: "",
                  password: userData.password,
                  user: userData.user,
                },
                history
              )
            );
            return;
          }
        }
      }
    })
    .catch((error) => {
      const { response } = error;
      console.error(response);
      if (response) {
        const { data } = response;
        datadispatch = authResponse(
          response.status,
          !!data.statusCode ? data.statusCode : "500",
          data.result.informationCode,
          data.result.blockingtime,
          !!data.result.information && data.statusCode !== 500
            ? data.result.information
            : DEFAULTRESPONSE.noResponseFromApi
        );
      } else {
        datadispatch = authResponse(
          500,
          500,
          "",
          0,
          DEFAULTRESPONSE.noResponseFromApi
        );
      }
    })
    .then(function () {
      if (!isGoHome) {
        changeLoading(dispatch, false);
        dispatch({
          type: SET_CURRENT_USER,
          payload: datadispatch,
        });
      }
    });
};

export /**
 * AuthAction changePassword ( Change Password  )
 *
 * @method POST
 * @param {history} history
 */
const changePassword = (userData, history) => async (dispatch) => {
  let datadispatch = {};
  changeLoading(dispatch, true);
  await axios
    .post(API_AUTHENTICATION + "/api/login/ChangePassword", userData)
    .then((response) => {
      const { data } = response;

      if (history)
        history.push({
          pathname: "/Alert",
          state: {
            message: data.result.information,
            pagename: "/Login",
          },
        });
    })
    .catch((error) => {
      const { response } = error;
      console.error(response);
      if (response) {
        const { data } = response;
        datadispatch = authResponse(
          response.status,
          !!data.statusCode ? data.statusCode : "500",
          data.result.informationCode,
          data.result.blockingtime,
          !!data.result.information && data.statusCode !== 500
            ? data.result.information
            : DEFAULTRESPONSE.noResponseFromApi
        );
      } else {
        datadispatch = authResponse(
          500,
          500,
          "",
          0,
          DEFAULTRESPONSE.noResponseFromApi
        );
      }
    })
    .then(function () {
      changeLoading(dispatch, false);
    });
  dispatch({
    type: SET_CURRENT_USER,
    payload: datadispatch,
  });
};

export /**
 * AuthAction SendEmailActivationPassword (  Send Email  )
 *
 * @method POST
 * @param {history} history
 */
const SendUserActivationEmail = (userData) => async (dispatch) => {
  changeLoading(dispatch, true);
  await axios
    .post(API_AUTHENTICATION + "/api/user/SendUserActivationEmail", userData)
    .then((response) => {
      const { data } = response;
      if (
        !!response.data.statusDetails &&
        response.data.statusDetails.length > 0
      ) {
        dispatch({
          type: SEND_ACTIVATION_EMAIL,
          payload: {
            code: response.data.statusDetails[0].code,
            message: data.result,
          },
        });
      }
    })
    .catch((error) => {
      const { response } = error;
      if (
        !!response.data.statusDetails &&
        response.data.statusDetails.length > 0
      ) {
        dispatch({
          type: SEND_ACTIVATION_EMAIL,
          payload: {
            code: response.data.statusDetails[0].code,
            message: response.data.statusDetails[0].description,
          },
        });
      }
    });
};

export /**
 * AuthAction tokenValidation ( token Validation )
 *
 * @method GET
 * @param {history} history
 */
const tokenValidation =
  (user, token, currentApp, history, option) => async (dispatch) => {
    const path = history.location.pathname;

    try {
      const requestData = {
        headers: {
          user: user,
          token: token,
          option: option,
          currentApp,
        },
      };
      const response = await axios.get(
        API_AUTHENTICATION + "/api/login/TokenValidation",
        requestData
      );
      dispatch({
        type: SET_CURRENT_USER,
        payload: response.data.result,
      });

      if (
        option !== undefined &&
        response.status === 200 &&
        !(path.toLowerCase() !== "/login" && path !== "/")
      ) {
        history.push({
          pathname:
            path.toLowerCase() !== "/login" && path !== "/" ? path : "/users",
        });
        dispatch({
          type: SET_IS_AUTHENTICATED,
          payload: true,
        });
      }
    } catch (error) {
      const { response } = error;
      console.error(response);
      if (response) {
        const { data } = response;
        if (history) {
          var message = data
            ? data.result.information
            : DEFAULTRESPONSE.noResponseFromApi;
          if (option === 1) {
            localStorage.clear();
            history.push({
              pathname: "/login",
            });
          } else {
            if (data.result.isUserActivation) {
              dispatch({
                type: IS_INVALID_TOKEN,
                payload: {
                  ok: true,
                  message: message,
                },
              });
            } else {
              history.push({
                pathname: "/Alert",
                state: {
                  message: message,
                  pagename: "/Login",
                },
              });
            }
          }
        }
      }
    }
  };

export /**
 * AuthAction logoutUser ( set token for close session, use history param for redirect to login page  )
 *
 * @method GET
 * @param {history} history
 */
const logoutUser = (history) => async () => {
  assignAuthTokenAndRefreshToken(false);
  if (history)
    history.push({
      pathname: "/login",
      state: { message: "Sesión finalizada exitosamente" },
      username: JSON.parse(localStorage.getItem("userTokenData")).name,
    });

  localStorage.removeItem("userTokenData");
};

function authResponse(
  httpCode,
  responseCode,
  infoCode,
  userTime,
  message,
  failedLoginInformation
) {
  return {
    httpCode: httpCode,
    responseCode: responseCode,
    message: message,
    responseStat: infoCode,
    timeUser: userTime,
    failedLoginInformation: failedLoginInformation,
  };
}

function authResponse2(
  httpCode,
  responseCode,
  infoCode,
  userTime,
  message,
  url,
  code,
  enterprise,
  token,
  failedLoginInformation
) {
  return {
    httpCode: httpCode,
    responseCode: responseCode,
    responseStat: infoCode,
    timeUser: userTime,
    message: message,
    url,
    code: code,
    enterprise: enterprise,
    token: token,
    failedLoginInformation: failedLoginInformation,
  };
}

function validateAuth(
  httpCode,
  responseCode,
  infoCode,
  message,
  username,
  authentication,
  identityProviderId
) {
  return {
    httpCode: httpCode,
    responseCode: responseCode,
    responseStat: infoCode,
    user: username,
    message: message,
    authType: authentication,
    identityProviderId: identityProviderId,
  };
}

function changeLoading(dispatch, status) {
  dispatch({
    type: LOADING,
    payload: status,
  });
}

/**
 * validateTokenAction Validar token
 *
 * @method validateTokenAction
 * @param {queryString} queryString
 */
export const validateTokenAction = (queryString) => async (dispatch) => {
  try {
    assignAuthTokenAndRefreshToken(false);
    if (!!queryString) {
      const tokenAndRefreshToken = await decodeTokenData(queryString);
      await loginTokenValidation(tokenAndRefreshToken, dispatch);
    }
  } catch (err) {
    console.error(err);
    redirectToLogin();
  } finally {
    // detener loading
  }
};

/**
 * AuthAction loginTokenValidation ( set user credentials to server for login attempt )
 *
 * @method GET
 * @param {*} userData
 */
async function loginTokenValidation(tokenAndRefreshToken, dispatch) {
  try {
    const token = tokenAndRefreshToken.token;
    const decodedTokenJwt = jwt_decode(token);
    if (tokenValidity(decodedTokenJwt)) {
      const userTokenData = {
        userId: decodedTokenJwt.jti,
        nameid: decodedTokenJwt.nameid,
        name: decodedTokenJwt.given_name.toLowerCase(),
        surname: decodedTokenJwt.family_name.toLowerCase(),
        customerName: decodedTokenJwt.customer_name.toLowerCase(),
        privileges: decodedTokenJwt.PIF_PRIVILEGE,
      };
      assignAuthTokenAndRefreshToken(tokenAndRefreshToken);
      dispatch({
        type: GET_USER_DATA,
        payload: userTokenData,
      });
      localStorage.setItem("userTokenData", JSON.stringify(userTokenData));
    } else {
      redirectToLogin();
    }
  } catch (error) {
    const { response } = error;

    if (response) {
      console.error(error);
      redirectToLogin();
    }
  }
}

/**
 * decodificar token dentro de un querystring
 *
 * @method
 * @param {queryString} queryString
 */
async function decodeTokenData(queryString) {
  try {
    const paramsChar = decodeURIComponent(queryString);
    const aesCbc = new aesjs.ModeOfOperation.cbc(encryptionKey, encryptionIv);
    const encryptedtext = aesjs.utils.hex.toBytes(
      new Buffer(paramsChar || "", "base64").toString("hex")
    );
    const decryptedBytes = aesCbc.decrypt(encryptedtext);
    const decryptedText = aesjs.utils.utf8.fromBytes(decryptedBytes);
    const cleanParamsObj = clearQueryVariable(decryptedText);
    const paramsObj = JSON.parse(cleanParamsObj);

    if (paramsObj.token !== undefined && paramsObj.token !== null) {
      const tokens = {
        token: paramsObj.token,
        refreshToken: paramsObj.refreshToken,
        encryptedToken: queryString,
      };
      return tokens;
    } else {
      redirectToLogin();
    }
  } catch (err) {
    console.error(err);
    redirectToLogin();
  }
}

/**
 * limpia el querystring
 *
 * @method
 * @param {paramsChar} paramsChar
 */
function clearQueryVariable(paramsChar) {
  try {
    // preservar nuevas líneas, etc. - JSON válido
    paramsChar = paramsChar
      .replace(/\\n/g, "\\n")
      .replace(/\\'/g, "\\'")
      .replace(/\\"/g, '\\"')
      .replace(/\\&/g, "\\&")
      .replace(/\\r/g, "\\r")
      .replace(/\\t/g, "\\t")
      .replace(/\\b/g, "\\b")
      .replace(/\\f/g, "\\f");
    // eliminar caracteres JSON no imprimibles y otros no válidos
    paramsChar = paramsChar.replace(/[\000-\031\200-\377]+/g, "");
    return paramsChar;
  } catch (err) {
    console.error("Error limpiando parametros:", err);
  }
}

/**
 * validar vigencia del token
 *
 * @method isValidToken
 * @param {querytokenString} token
 */
export const isValidToken = (token) => {
  try {
    const decodedTokenJwt = jwt_decode(token);
    return tokenValidity(decodedTokenJwt);
  } catch (err) {
    console.error("Error validando Token:", err);
    return false;
  }
};

/**
 * valida que la vigencia del token se mayor o igual a 20 segundos
 *
 * @method isValidToken
 * @param {querytokenString} token
 */
export const tokenValidationTime = (token) => {
  try {
    const decodedTokenJwt = jwt_decode(token);
    const currentDate = moment().utc();
    const dateExpiresToken = moment.unix(decodedTokenJwt.exp).utc();
    return (
      moment(dateExpiresToken).diff(currentDate, "seconds") < TIME_REFRESH_TOKEN
    );
  } catch (err) {
    console.error("Error validando Token:", err);
    return false;
  }
};

/**
 * Invoca el api de refreshToken y retorna un nuevo token
 * @param {refreshData} refreshData
 */
export const refreshToken = (refreshData) => async () => {
  try {
    await axios
      .post(API_AUTHENTICATION + "/api/Login/RefreshToken", refreshData)
      .then((response) => {
        assignAuthTokenAndRefreshToken(response.data.result.token);
      });
  } catch (err) {
    console.error("Error refrescando Token:", err);
  }
};

/**
 * validar vigencia del token
 *
 * @method tokenValidity
 * @param {querytokenString} token
 */
export const tokenValidity = (decodedToken) => {
  try {
    const currentDate = moment().utc();
    const dateExpiresToken = moment.unix(decodedToken.exp).utc();
    return moment(dateExpiresToken).isAfter(currentDate);
  } catch (err) {
    console.error("Error validando Token:", err);
    return false;
  }
};

/**
 * Redireccionar a login
 *
 * @method redirectToLogin
 * @param
 */
export async function redirectToLogin() {
  await logoutUser();
}

export const generateTokens = (body) => async (dispatch, getState) => {
  try {
    const headers = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: body === undefined ? "" : body.token,
      },
    };

    const res = await axios.post(
      API_AUTHENTICATION + "/api/login/GenerateTokens",
      body,
      headers
    );
    dispatch({
      type: GENERATE_TOKENS,
      payload: res.data.result,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error,
    });
  }
};

/**
 * @method revokeTokenUsers
 * remueve el token de el localStorage consulta el servicio de removeToken para invalidar el token
 * y redirecciona a la vista de login
 * @author kevin.olarte
 */
export const revokeTokenUsers = (setLoading) => async (dispatch, getState) => {
  try {
    let revokeToken = {
      UserName: JSON.parse(localStorage.getItem("userTokenData")).nameid,
      Quantity: 1,
      AppName: JSON.parse(localStorage.getItem("_appName")),
      RefreshToken: localStorage.getItem("refreshToken"),
    };
    const headers = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    };

    const res = await axios.put(
      API_AUTHENTICATION + "/api/login/RevokeToken",
      revokeToken,
      headers
    );
    if (res.status === 200) {
      dispatch({
        type: REVOKE_TOKENS,
        payload: res.data.result,
      });
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("jwtToken");
      localStorage.removeItem("userTokenData");
      localStorage.removeItem("encryptedToken");
      const { data } = res;
      window.location.href = data.result.information;
    } else {
      return false;
    }
    setLoading(false);
  } catch (error) {
    console.log("Error", error);
  }
};



/**
 * @method validateAuthenticationType
 * Consulta el tipo de autenticación permitido para el usuario
 * por medio del username y redirige en caso de que sea SAML
 * @param {*} userData
 */
export const validateAuthenticationType = (userData) => async (dispatch) => {
  const params = new URLSearchParams();
  
  params.append("user", userData);
  let datadispatch = {};
  await axios
    .post(API_AUTHENTICATION + "/api/login/LoginType", params)
    .then(async (response) => {
      const { data } = response;
      if (data.result.authenticationType === 2) {
        const dataObject = JSON.parse(data.result.identityProviderValue);
        await axios.post(API_SAML_AUTHENTICATION + "/session/sso/", null, {
          headers: {
            'IdentityProviderURL' : dataObject.value
          }
        })
              .then(async (response) => {
                const html = response.data;
                const _appName = localStorage.getItem('_appName').replaceAll('"',"");
                const reloadWindow = window.open(localStorage.getItem('lastUrl'),  (window.self !== window.top) ? "_blank" : "_self");
                if(window.self !== window.top) {
                  reloadWindow.opener.embebedSSOClick = function(data){
                    const lastUrl = localStorage.getItem('lastUrl')+ "?appName=" + _appName;
                    localStorage.setItem('_appName',_appName);
                    localStorage.setItem("jwtToken", TOKEN_PREFIX + data.token);
                    localStorage.setItem("refreshToken", data.refreshToken);
                    localStorage.setItem("encryptedToken",data.encryptedToken);
                    reloadWindow.opener.location = lastUrl;
                  };
                };
                reloadWindow.document.write(html);
              });
      } else {
        datadispatch = validateAuth(
          response.status,
          data.statusCode,
          data.result.informationCode,
          data.result.information,
          data.result.userName,
          data.result.authenticationType,
          data.result.identityProviderId
        );
      }
    })
    .catch(async (error) => {
      const { response } = error;
      if (response) {
        try {
          const { data } = response;
          datadispatch = authResponse(
            response.status,
            !!data.statusCode ? data.statusCode : "500",
            data.result.informationCode,
            0,
            data.result.information,
            data.result.failedLoginInformation
          );
        } catch {
          datadispatch = authResponse(
            500,
            500,
            "R11",
            0,
            "Ocurrió un error inesperado al procesar su solicitud, por favor intente de nuevo."
          );
        }
      } else {
        datadispatch = authResponse(
          500,
          500,
          "R11",
          0,
          "Ocurrió un error inesperado al procesar su solicitud, por favor intente de nuevo."
        );
      }
    });

  changeLoading(dispatch, false);
  dispatch({
    type: SET_CURRENT_USER,
    payload: datadispatch,
  });
};
